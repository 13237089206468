@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&display=swap);
.BottomNav_wrapper__2gA9m {
  bottom: 0;
  background-color: var(--color-label-light);
  padding: calc(var(--grid)/2) var(--grid);
  position: absolute;
  width: 100%;
}
.BottomNav_wrapper__2gA9m a,
.BottomNav_wrapper__2gA9m a:visited {
  color: var(--color-label-dark);
}
.BottomNav_contact__1kDAy {
  float: right;
}
.Container_container__2qCpK {
  box-sizing: border-box;
  max-width: var(--container-max-width);
  padding-left: calc(var(--grid) / 2);
  padding-right: calc(var(--grid) / 2);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.Container_containerFluid__1nh-e {
  padding-left: 0;
  padding-right: 0;
}

.Container_containerVSpace__cfneS {
  margin-top: var(--grid);
  margin-bottom: var(--grid);
}

.Container_containerOneCol__3mHfc {
  max-width: 400px;
}
.TopNav_container__1MOkw {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}
.TopNav_wrapper__3F4Ni {
  box-shadow: none;
  background-color: transparent;
}
.TopNav_wrapperEdit__2dnjp {
  background-color: var(--color-editing);
}
.Modal_modal__3ienQ {
  border-radius: 23px;
  min-width: 320px;
  padding: 0 var(--grid);
}
.Modal_closeButton__12JSg {
  position: absolute;
  right: var(--grid);
  top: var(--grid);
}
.Grid_column__2DZM- {
  padding: 0 calc(var(--grid) / 2);
  position: relative;
}

.Grid_grid__2nodd {
  display: flex;
  justify-content: space-between;
  margin: 0 calc(var(--grid) / -2);
  left: 0;
  right: 0;
}
.Grid_grid__2nodd.Grid_responsive__3nX-K {
  flex-wrap: wrap;
}
.Grid_grid__2nodd.Grid_equal__2w4Qb>.Grid_column__2DZM- {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .Grid_grid__2nodd {
    margin: 0;
  }
  .Grid_grid__2nodd.Grid_responsive__3nX-K>.Grid_column__2DZM- {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
.CtaButton_button__22Bau {
  background-color: var(--color-white);
  border-radius: 15px;
  box-shadow: rgb(0, 0, 0, 0.5) 0px 2px 4px;
  display: block;
  font-weight: 600;
  margin: var(--grid) 0 0 0;
  padding: 15px;
  text-align: center;
  width: 100%;
}
.CtaButton_buttonPrimary__3AKHT .ctaText,
.CtaButton_button__22Bau .ctaText {
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .CtaButton_button__22Bau {
    min-width: auto;
  }
}
.CtaButton_button__22Bau:hover {
  background-color: var(--color-white);
}
.CtaButton_button__22Bau[disabled] {
  background-color: var(--color-label-light) !important;
  border-color: var(--color-label-light) !important;
  color: var(--color-med-gray) !important;
}
.CtaButton_buttonInline__1YVWm {
  min-width: auto;
}
.CtaButton_buttonPrimary__3AKHT.CtaButton_buttonSolid__3G8RV {
  color: var(--color-label-light);
  background-color: var(--color-primary);
}
.CtaButton_buttonPrimary__3AKHT {
  border: 1px var(--color-primary) solid;
  color: var(--color-primary);
}
.CtaButton_buttonPrimary__3AKHT:hover {
  border-color: var(--color-hover);
  color: var(--color-hover);
}
.CtaButton_button__22Bau .CtaButton_buttonIcon__3jIJj {
  display: inline;
}
.CtaButton_button__22Bau .CtaButton_buttonIcon__3jIJj svg,
.CtaButton_button__22Bau .CtaButton_buttonIcon__3jIJj img {
  height: 16px;
  vertical-align: middle;
  width: 14px;
}
.AddCard_qrCode__2D8JW {
  display: block;
  margin: calc(var(--grid)/2) auto;
}
.AddCard_qrWrap__3L4GG {
  padding: calc(var(--grid)/2) 0;
  text-align: center;
  text-transform: uppercase;
}
.StatCard_wrapper__1e0rX {
  border-radius: 20px;
  box-shadow: rgb(0,0,0,0.5) 0 2px 4px;
  display: inline-block;
  margin-right: var(--grid);
  padding: 14px;
  position: relative;
}
.StatCard_wrapper__1e0rX:last-child {
  margin-right: 0;
}

.StatCard_wrapper__1e0rX .cardText {
  font-weight: 700;
}

.StatCard_iconStyle__nrCnX {
  height: 20px;
  right: calc(var(--grid)/2);
  top: var(--grid);
  position: absolute;
}

.StatCard_wrapper__1e0rX h1,
.StatCard_wrapper__1e0rX p {
  margin: 0px;
}
.WaveBg_wrapper__3pbeM {
  background-image: url(/static/media/wave-bg.c20d7caa.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.Form_form__32yOr {
  position: relative;
  margin: 0 auto;
}

.Form_inputWrap__NMz99 {
  display: block;
  width: 100%;
}

.Form_inputWrap__NMz99:before,
.Form_select__1oXv2:before {
  border-bottom-color: var(--color-label-med);
}

.Form_input__1vbEq {
  color: var(--color-label-dark);
}

.Form_fieldError__YPkw6 {
  color: var(--color-error);
  margin-top: calc(var(--grid) / 4)
}

.Form_select__1oXv2 {
  display: block;
}

.Form_checkboxLabel__Bpujs {
  text-transform: none;
}

.Form_alert__2rT7_ {
  background-color: transparent;
  border-radius: 15px;
  color: var(--color-label-dark);
  border: 1px solid var(--color-label-dark);
  font-size: 16px;
  font-weight: 500;
  margin: var(--grid) 0;
  padding: 15px 30px;
  position: relative;
}
.Form_alert__2rT7_ .Form_closeAlert__14W9i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.Form_alert__2rT7_.success {
  border-color: var(--color-success);
}
.Form_alert__2rT7_.error {
  border-color: var(--color-error);
}

.Form_dropzone__3HWba {
  align-items: center;
  border: 1px solid var(--color-med-gray);
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  margin-top: calc(var(--grid)/2);
  height: 150px;
  overflow: hidden;
  padding: calc(var(--grid)/2);
  width: 100%;
}
.Form_dropzone__3HWba img {
  max-width: 80%;
  max-height: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .Dashboard_column__W-sUd {
    max-width: 400px;
  }
}
.ButtonGroup_buttonGroup__1tImp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: calc(-1 * var(--grid) / 2);
  margin-right: calc(-1 * var(--grid) / 2);
  width: calc(100% + var(--grid));
}
.ButtonGroup_buttonGroup__1tImp button,
.ButtonGroup_buttonGroup__1tImp a {
  margin-left: calc(var(--grid) / 2);
  margin-right: calc(var(--grid) / 2);
}
@media screen and (max-width: 768px) {
  .ButtonGroup_buttonGroup__1tImp {
    flex-direction: column-reverse;
    width: 100%;
  }
}
.App_App__16ZpL {
  text-align: center;
}

.App_wrapper__2Rh_f {
  min-height: 100vh;
  padding-bottom: calc(4 * var(--grid));
  position: relative;
}

.App_pricingTable__d3HC_ {
  border-collapse: collapse;
  line-height: 2em;
  margin: var(--grid) 0;
  text-align: left;
  width: 100%;
}
.App_pricingTable__d3HC_ thead tr {
  border-bottom: 2px solid var(--color-label-light)
}
.App_vSpace__2lRo1 {
  margin: var(--grid) 0;
}
.Preview_wrapper__39D5U {
  background-color: var(--color-label-dark);
  height: 100%;
  width: 100%;
}

.Preview_modeSwitch__w3cNx {
  width: 100%;
}
.Preview_modeSwitch__w3cNx button {
  background-color: var(--color-white);
  border: 1px solid var(--color-med-gray);
  border-width: 0 1px 1px 0;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  padding: calc(var(--grid) / 2);
  width: 50%;
}
button.Preview_modeActive__MUvPe {
  background-color: var(--color-label-light);
}

.Preview_icon__k-tvz {
  height: 20px;
  float: right;
}

.Preview_iosPreview__3EgQg {
  color: var(--color-white);
  padding: var(--grid);
}
.Preview_androidPreview__nmJHJ label,
.Preview_iosPreview__3EgQg label {
  margin-bottom: 0;
}

.Preview_androidPreview__nmJHJ h3,
.Preview_androidPreview__nmJHJ h4,
.Preview_androidPreview__nmJHJ h5,
.Preview_iosPreview__3EgQg h3,
.Preview_iosPreview__3EgQg h4 {
  font-weight: 400;
  margin: 0;
}

.Preview_androidPreview__nmJHJ a,
.Preview_androidPreview__nmJHJ p,
.Preview_iosPreview__3EgQg a,
.Preview_iosPreview__3EgQg p {
  display: block;
  margin: 0;
}

.Preview_androidPreview__nmJHJ a,
.Preview_androidPreview__nmJHJ a:visited {
  color: var(--color-body);
}

.Preview_androidPreview__nmJHJ {
  font-size: 14px;
  padding: var(--grid);
}

.Preview_iosCard__1HvAQ {
  margin-bottom: var(--grid);
}

.Preview_androidCard__30S8_ {
  margin: var(--grid) 0;
}

.Preview_androidFront__2V6dY {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Preview_androidLogo__4E1kQ {
  background-size:cover;
  border-radius: 30px;
  margin: 0px auto;
  height: 60px;
  overflow: hidden;
  width: 60px;
}
@media screen and (max-width: 768px) {
  .Update_preview__LU57f {
    display: none;
  }
}
:root {
  --color-white: #ffffff;
  --color-primary: #7835FA;
  --color-editing: #00A6FF;
  --color-hover: #FFA400;
  --color-error: #FF5722;
  --color-success: #46D987;
  --color-accent-turquoise: #00D9D9;
  --color-accent-yellow: #FFE300;
  --color-label-light: #EDEDED;
  --color-label-med: #83878C;
  --color-label-dark: #4E4E61;
  --color-med-gray: #B9B9B9;
  --color-copy: #424140;
}
:root {
  --font-nunito: 'Nunito Sans', sans-serif;
  --font-size-cta: 14px;
  --grid: 27px
}
.ctaText,
.cardText {
  font-size: 14px;
  font-size: var(--font-size-cta);
  line-height: 136%;
  font-weight: 500;
  letter-spacing: 0.7px;
}

h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 136%;
}

h2 {
  font-size: 30px;
  font-weight: 800;
  line-height: 136%;
}

h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 133%;
}

h4 {
  font-size: 20px;
  font-weight: 800;
  line-height: 135%;
}

label {
  color: var(--color-label-med);
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 140%;
}

.introText {
  font-size: 18px;
  line-height: 144%;
}
.finePrint {
  font-size: 12px;
}
body {
  color: var(--color-copy);
  margin: 0;
  font-family: var(--font-nunito);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

:root {
  --container-max-width: 1024px;
}
