.App {
  text-align: center;
}

.wrapper {
  min-height: 100vh;
  padding-bottom: calc(4 * var(--grid));
  position: relative;
}

.pricingTable {
  border-collapse: collapse;
  line-height: 2em;
  margin: var(--grid) 0;
  text-align: left;
  width: 100%;
}
.pricingTable thead tr {
  border-bottom: 2px solid var(--color-label-light)
}
.vSpace {
  margin: var(--grid) 0;
}