@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&display=swap');
:root {
  --font-nunito: 'Nunito Sans', sans-serif;
  --font-size-cta: 14px;
  --grid: 27px
}
.ctaText,
.cardText {
  font-size: var(--font-size-cta);
  line-height: 136%;
  font-weight: 500;
  letter-spacing: 0.7px;
}

h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 136%;
}

h2 {
  font-size: 30px;
  font-weight: 800;
  line-height: 136%;
}

h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 133%;
}

h4 {
  font-size: 20px;
  font-weight: 800;
  line-height: 135%;
}

label {
  color: var(--color-label-med);
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 140%;
}

.introText {
  font-size: 18px;
  line-height: 144%;
}
.finePrint {
  font-size: 12px;
}