.wrapper {
  background-color: var(--color-label-dark);
  height: 100%;
  width: 100%;
}

.modeSwitch {
  width: 100%;
}
.modeSwitch button {
  background-color: var(--color-white);
  border: 1px solid var(--color-med-gray);
  border-width: 0 1px 1px 0;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  padding: calc(var(--grid) / 2);
  width: 50%;
}
button.modeActive {
  background-color: var(--color-label-light);
}

.icon {
  height: 20px;
  float: right;
}

.iosPreview {
  color: var(--color-white);
  padding: var(--grid);
}
.androidPreview label,
.iosPreview label {
  margin-bottom: 0;
}

.androidPreview h3,
.androidPreview h4,
.androidPreview h5,
.iosPreview h3,
.iosPreview h4 {
  font-weight: 400;
  margin: 0;
}

.androidPreview a,
.androidPreview p,
.iosPreview a,
.iosPreview p {
  display: block;
  margin: 0;
}

.androidPreview a,
.androidPreview a:visited {
  color: var(--color-body);
}

.androidPreview {
  font-size: 14px;
  padding: var(--grid);
}

.iosCard {
  margin-bottom: var(--grid);
}

.androidCard {
  margin: var(--grid) 0;
}

.androidFront {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.androidLogo {
  background-size:cover;
  border-radius: 30px;
  margin: 0px auto;
  height: 60px;
  overflow: hidden;
  width: 60px;
}