@import './colors.css';
@import './typography.css';
body {
  color: var(--color-copy);
  margin: 0;
  font-family: var(--font-nunito);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

:root {
  --container-max-width: 1024px;
}