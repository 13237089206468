:root {
  --color-white: #ffffff;
  --color-primary: #7835FA;
  --color-editing: #00A6FF;
  --color-hover: #FFA400;
  --color-error: #FF5722;
  --color-success: #46D987;
  --color-accent-turquoise: #00D9D9;
  --color-accent-yellow: #FFE300;
  --color-label-light: #EDEDED;
  --color-label-med: #83878C;
  --color-label-dark: #4E4E61;
  --color-med-gray: #B9B9B9;
  --color-copy: #424140;
}